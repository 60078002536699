import { useEffect, useState } from 'react';
import { Table, TableProps } from 'antd';
import { Link } from 'react-router-dom';
import { getRequest, ResponseDataType } from 'api';


interface BillErrorDataType {
  id: string;
  description: string;
  row: string;
  type: number;
  createdDate: number;
  mstSchool: string;
}

const columns: TableProps<BillErrorDataType>['columns'] = [
  {
    title: 'Mô tả lỗi',
    dataIndex: 'description',
    key: 'description',
    width: '300px',
  },
  {
    title: 'Dòng lỗi',
    dataIndex: 'row',
    key: 'row',
    width: '100px',
  },
  {
    title: 'Loại lỗi',
    dataIndex: 'type',
    key: 'type',
    width: '100px',
    render: (type) => {
      switch (type) {
        case 1:
          return 'Lặp mã hoá đơn';
        case 2:
          return 'Không có mã học sinh';
        default:
          return 'Không xác định'; // Default case if the type is not recognized
      }
    },
  },
  {
    title: 'Thời điểm lỗi',
    dataIndex: 'createdDate',
    key: 'createdDate',
    width: '140px',
    render: (createdDate) =>
      new Date(createdDate).toLocaleString('vi-VN', {
        dateStyle: 'short',
        timeStyle: 'short',
      }),
  },
  {
    title: 'MST',
    dataIndex: 'mstSchool',
    key: 'mstSchool',
    width: '140px',
  },
  {
    title: 'Hành động',
    dataIndex: 'id',
    key: 'action',
    width: '140px',
    render: (id) => <Link to={`/bill-list`}>Chi tiết</Link>,
  },
];

export const CreateBillError = () => {
  const [billList, setBillList] = useState<BillErrorDataType[]>([]);
  const schoolId = localStorage.getItem('schoolId');
  console.log('schoolId', schoolId);
  let url = `/cms/invoice/list-invoices-error/${schoolId}`;
  useEffect(() => {
    const fetchData = async () => {
      const response: ResponseDataType<BillErrorDataType[]> = await getRequest(
        url
      );
      console.log('response', response);
      if (response) {
        if (response.code === 200) {
          setBillList(response.info || []);
        } else {
          console.log(response.msg);
        }
      };
    }
    fetchData();
  }, []);

  return (
    <div>
      <Table columns={columns} dataSource={billList} />
    </div>
  );
};
